<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div @click="$router.replace('/')" class="icon">
              <ModalCloseIcon :dark="true" />
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="content">
              <div class="logo">
                <img src="../assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <h2>Nice to meet you!</h2>
              <h3 v-if="!invitation">
                Let's get to know each other.
              </h3>
              <h3 v-else>
                Complete your registration.
              </h3>
              <div class="inputs">
                <div class="form-group names">
                  <input
                    v-model="firstName"
                    id="first_name"
                    type="text"
                    class="text-input dark"
                    placeholder="First name"
                  />
                  <input
                    v-model="lastName"
                    id="last_name"
                    type="text"
                    class="text-input dark"
                    placeholder="Last name"
                  />
                </div>
                <div class="form-group" v-if="!invitation">
                  <input
                    v-model="email"
                    id="email"
                    type="email"
                    class="text-input dark"
                    placeholder="Email address"
                  />
                </div>
                <div class="form-group" v-if="invitation">
                  <input
                    v-model="talentName"
                    id="talent_name"
                    type="text"
                    class="text-input dark"
                    placeholder="Artist/Producer Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    v-model="password"
                    id="password"
                    type="password"
                    class="text-input dark"
                    placeholder="Password"
                  />
                </div>
                <ul class="unstyled centered">
                  <li>
                    <input
                      v-model="acceptedTerms"
                      class="styled-checkbox"
                      id="styled-checkbox-1"
                      type="checkbox"
                      value="true"
                    />
                    <label for="styled-checkbox-1">
                      I agree with terms and conditions and I’m over the age of
                      18.
                    </label>
                  </li>
                </ul>
              </div>
              <button
                @click="onSignUpClick()"
                class="primary"
                :disabled="!enableSignupButton || isLoading"
              >
                {{ isLoading ? "Loading..." : "Create account" }}
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import REGISTER from "@/api/mutations/REGISTER.gql";
import REGISTER_INVITED from "@/api/mutations/REGISTER_INVITED.gql";

export default {
  name: "Signup",
  components: { ModalCloseIcon },
  data() {
    return {
      firstName: null,
      lastName: null,
      talentName: null,
      email: null,
      password: null,
      acceptedTerms: false,
      isLoading: false
    };
  },
  computed: {
    invitation() {
      return this.$route.query.invitation;
    },
    enableSignupButton() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.password ||
        !this.acceptedTerms
      )
        return false;

      if (!this.invitation && !this.email) return false;
      if (this.invitation && !this.talentName) return false;

      return true;
    }
  },
  methods: {
    async onSignUpClick() {
      try {
        this.isLoading = true;
        await this.register();
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    async register() {
      if (!this.invitation) {
        const result = await this.$apollo.mutate({
          mutation: REGISTER,
          variables: {
            email: this.email.trim(),
            first_name: this.firstName.trim(),
            last_name: this.lastName.trim(),
            password: this.password
          }
        });
        const { register } = result.data;
        this.$token = register.token;
        this.$store.commit("account/authSuccess", register);
        this.$router.push({ name: "SignupVerification" });
      } else {
        const result = await this.$apollo.mutate({
          mutation: REGISTER_INVITED,
          variables: {
            token: this.invitation,
            first_name: this.firstName.trim(),
            last_name: this.lastName.trim(),
            talent_name: this.talentName.trim(),
            password: this.password
          }
        });
        const { register_invited } = result.data;
        this.$token = register_invited.token;
        this.$store.commit("account/authSuccess", register_invited);
        this.$router.push({ name: "Songs" });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 32px;
}

.modal-body {
  width: 100%;
  height: calc(100vh - 164px);
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .logo {
      margin-bottom: 32px;
    }
    h2 {
      color: $white;
      margin-bottom: 16px;
    }
    h3 {
      color: $white;
      font-weight: 300;
      margin-bottom: 32px;
    }
    .inputs {
      width: 100%;
      margin-bottom: 32px;
      .form-group {
        margin-bottom: 24px;
        input {
          @include details-light();
          color: $white;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }
      }
      .names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          width: 48%;
        }
      }
      .styled-checkbox {
        position: absolute;
        opacity: 0;

        & + label {
          display: flex;
          position: relative;
          cursor: pointer;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }

        & + label:before {
          content: "";
          margin-top: 2px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: text-top;
          width: 14px;
          height: 14px;
          background: transparent;
          border: 1px solid white;
        }

        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        &:checked + label:before {
          background: transparent;
        }

        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        &:checked + label:after {
          content: "";
          position: absolute;
          left: 2px;
          top: 6px;

          width: 2px;
          height: 2px;
          margin-top: 2px;
          background: white;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.22px;
      padding: 10px 30px 12px;
    }
  }
}
</style>
